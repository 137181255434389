import axios from 'axios'
import { set } from 'vue'

export default {
  state: {
    lineGroup: {
      isLoaded: false,
      list: []
    },
    huntList: {
      isLoaded: false,
      list: []
    },
    huntPilot: {
      isLoaded: false,
      list: []
    },
    routePartition: {
      isLoaded: false,
      list: []
    },
    callPickupGroup: {
      isLoaded: false,
      list: []
    },
    translationPattern: {
      isLoaded: false,
      list: []
    }
    // callSearchSpace: {
    //   isLoaded: false,
    //   list: []
    // }
  },
  getters: {
    cucmGroupList: (state) => (groupName) => state[groupName]?.list
  },
  mutations: {
    SET_CUCM_GROUP_LIST(state, { groupName, list }) {
      set(state[groupName], 'list', list)
      set(state[groupName], 'isLoaded', true)
    }
  },
  actions: {
    async getCucmGroup({ state, commit }, { groupName, forceLoad = false }) {
      if (state[groupName].isLoaded && forceLoad === false) return

      const res = await axios.get(`/cucm/${groupName}/list`)
      commit('SET_CUCM_GROUP_LIST', {
        groupName,
        list: res.data.map((item) => {
          return {
            name: item
          }
        })
      })
    },
    async getAllCucmGroups({ dispatch }) {
      const groupNames = [
        'lineGroup',
        'huntList',
        'huntPilot',
        'routePartition',
        'callPickupGroup',
        'translationPattern'
      ]

      // Dispatch 'getCucmGroup' for each group in parallel
      await Promise.all(
        groupNames.map((groupName) => dispatch('getCucmGroup', { groupName }))
      )
    },
    async addCucmGroupList({ dispatch }, { groupName, form }) {
      console.log(groupName, form)

      const res = await axios({
        method: 'post',
        url: `/cucm/${groupName}/create`,
        params: { ...form }
      })
      dispatch('getCucmGroup', { groupName, forceLoad: true })
      return res.status
    }
    // async editLineGroup({ dispatch }, { id, data }) {
    //   const res = await axios.put(`/lineGroup/${id}`, data)
    //   dispatch('getLineGroup')
    //   return res.status
    // }
  }
}
