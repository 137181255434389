import axios from 'axios'
import store from '../store'
import router from '../router'

//TODO CHECK LOGIN REDIRECTION
export default function init() {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
  axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      console.error(err.response)
      if (err.response && err.response.status === 401) {
        store.dispatch('toggleSnackbar', {
          text: 'Η συνεδρία έληξε',
          color: 'error'
        })
        store.dispatch('logout')
        router.push('/login')
      }
      throw err
    }
  )
}
