<template>
  <v-app>
    <the-navigation-drawer
      v-if="isLoggedIn"
      :open="navDrawer"
      @drawerChange="navDrawer = $event"
    />

    <the-app-bar v-if="isLoggedIn" @drawerOpen="navDrawer = true" />

    <v-main>
      <router-view />
    </v-main>

    <v-sheet outlined class="white pa-2 d-flex">
      <v-img class="mx-auto" src="/assets/EKT-ESPA.png" max-width="800" />
    </v-sheet>

    <the-snackbar />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import TheNavigationDrawer from './components/TheNavigationDrawer.vue'
import TheAppBar from './components/TheAppBar.vue'
import TheSnackbar from './components/TheSnackbar.vue'

export default {
  components: {
    TheNavigationDrawer,
    TheAppBar,
    TheSnackbar
  },
  data() {
    return {
      navDrawer: false
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  }
}
</script>

<style lang="scss">
.v-main__wrap {
  background: #f1f1f1 !important;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.flex-1 {
  flex: 1 1 auto;
}

.scrollable {
  overflow-y: auto;
}

.clickable {
  cursor: pointer;
}

.h-80 {
  height: 80%;
}

.h-90 {
  height: 90%;
}

.h-95 {
  height: 95%;
}

.h-100 {
  height: 100%;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: var(--v-primary-base);
  transition: all 0.2s;

  &:hover {
    text-decoration: underline;
    color: var(--v-primary-lighten1);
  }
}

.cut-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// css overriding
::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #787878;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}
</style>
